import React from 'react';
import { useParams } from 'react-router-dom';
import { Box, Text, Heading, Button, HStack } from '@chakra-ui/react';
import { useState } from 'react';
const apiUrl = process.env.REACT_APP_BASE_API_URL; // Import the apiUrl from environment variables
const baseUrl = process.env.REACT_APP_BASE_BASE_URL; // Import the apiUrl from environment variables

const SuccessPage = () => {
  const { name, shareLink } = useParams();

  const link = `${baseUrl}/events/${name}/${shareLink}`

  const [copied, setCopied] = useState(false);


  const handleCopy = () => {
    navigator.clipboard.writeText(link)
      .then(() => {
        setCopied(true);
      })
      .catch((error) => {
        console.error('Copying to clipboard failed:', error);
      });
  };



  const handleShare = async () => {
    if (navigator.share) {
      try {
        await navigator.share({
          title: 'Event Link',
          text: 'Check out this event!',
          url: link,
        });
        console.log('Link shared successfully');
      } catch (error) {
        console.error('Error sharing link:', error);
      }
    } else {
      console.warn('Web Share API not supported in this browser');
    }
  };


  console.log(shareLink)

  return (
    <div>
      <Box width={'100vw'} height={'60vh'} display={'flex'} justifyContent={'center'} alignItems={'center'} color={'white'} marginTop={['10vh', '10vh', 'none', 'none']}>
        <Box width={['85vw', '85vw', '85vw', '34vw']} height={'45vh'} borderRadius={'12px'} backgroundColor={'#3F115B'} display={'flex'} justifyContent={'center'} alignItems={'center'} color={'white'} flexDirection={'column'}>

          <Box width={['70vw', '70vw', '70vw', '27vw']} height={'28vh'}>

            <Heading fontWeight={'200'} color={'#FFAC30'}>Event created successfully!</Heading>
            <br /><br />

            <Text width={'100%'} fontSize={'1.2rem'} textAlign={'start'}>Share this link with your friends:</Text>
            <br />

            <Text onClick={() => window.location.href = `/events/${name}/${shareLink}`} cursor={'pointer'}>
              {shareLink}
            </Text>
            <HStack>
              <Button
                margin={'0px auto'}
                marginTop={'5vh'}
                width={'50%'}
                fontWeight={'400'}
                backgroundColor={'#FFAC30'}
                color={'white'}
                _hover={{ color: 'black', border: '2px solid #3F115B', backgroundColor: 'white' }}
                onClick={handleCopy}
              >
                {copied ? 'Copied!' : 'Copy link'}
              </Button>


              <Button
                margin={'0px auto'}
                marginTop={'5vh'}
                width={'50%'}
                fontWeight={'400'}
                backgroundColor={'#FFAC30'}
                color={'white'}
                _hover={{ color: 'black', border: '2px solid #3F115B', backgroundColor: 'white' }}
                onClick={handleShare}
              >
                Share
              </Button>

            </HStack>

          </Box>


        </Box>
      </Box>

    </div>
  );
};

export default SuccessPage;
