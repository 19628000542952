import React from "react";
import { Box, background, position, button } from "@chakra-ui/react";
import { Text } from "@chakra-ui/react";
import {
  HStack,
  Flex,
  Icon,
  Divider,
  Button,
  Heading,
  VStack,
  FormControl,
  Input,
  Textarea,
  Image,
} from "@chakra-ui/react";
import img1 from "../../Assests/logo.png";


const Contact = () => {
  return (
    <div>
      <Box
        marginTop={"7vh"}
        width={"100vw"}
        // height={"100vh"}
      >
        <Flex
          width={"100%"}
          height={"100%"}
          flexDirection={{
            base: "column",
            sm: "column",
            md: "column",
            xl: "row",
          }}
        >
          <Image
            src={img1}
            width={{ base: "80%", sms: "80%", md: "45%", xl: "15%" }}
            h={"70vh"}
            margin={"auto"}
          ></Image>

          <VStack
            width={{ base: "80vw", sm: "80vw", md: "45vw", xl: "40vw" }}
            margin={"auto"}
          >
            <h2 style={{ fontSize: "1.8rem" }}>
              <Text style={{ color: "#000000" }}>CONTACT US </Text>
            </h2>

            <Text
              fontSize={{ base: "2rem" }}
              style={{ color: "#00008B", marginTop: "1vh" }}
              textAlign={"center"}
            >
              Request a Callback
            </Text>

            <FormControl isRequired marginTop={"2vh"}>
              <Input placeholder="Full name" />
            </FormControl>

            <FormControl isRequired marginTop={"2vh"}>
              <Input placeholder="Email" />
            </FormControl>

            <FormControl isRequired marginTop={"2vh"}>
              <Input placeholder="Mobile" />
            </FormControl>

            <FormControl isRequired marginTop={"2vh"}>
              <Textarea placeholder="enter description"></Textarea>
            </FormControl>

            <Button
              marginTop={"2vh"}
              color={"white"}
              fontWeight={"400"}
              backgroundColor={"#00008B"}
              padding={"5px 40px"}
              _hover={{
                color: "black",
                border: "2px solid #00008B",
                backgroundColor: "white",
              }}
              marginBottom={"5vh"}
            >
              Submit
            </Button>
          </VStack>
        </Flex>
      </Box>
    </div>
  );
};

export default Contact;
