import {
  BrowserRouter as Router,
  Routes,
  Route,
  Switch,
} from "react-router-dom";

import Header from "./components/Header";
import Home from "./components/Home";
import Footer from "./components/Footer";
import Newevent from "./components/pages/Newevent";
import SuccessPage from "./components/pages/SuccessPage";
import Userpage from "./components/pages/Userpage";
import MatchedPhotosPage from "./components/pages/MatchedPhotosPage";
import Wedding from "./components/pages/wedding";
import Social from "./components/pages/Social";
import School from "./components/pages/School";
import Marathons from "./components/pages/Marathons";
import Convocations from "./components/pages/Convocations";
import Events from "./components/pages/Events";
import PrivacyPolicy from "./components/pages/Privacypolicy";
import AboutUs from "./components/pages/About";
import Faq from "./components/pages/Faqs";
import Contact from "./components/pages/ContactUs";
import Pricing from "./components/pages/Pricing";
import Features from "./components/pages/Features";

function App() {
  return (
    <div className="App">
      <Router>
        <Header />

        <Routes>
          <Route path="/" element={<Home />}></Route>
          
          <Route path="/newevent" element={<Newevent />}></Route>
          
          <Route
            path="/success/:name/:shareLink"
            element={<SuccessPage />}
          ></Route>
          
          <Route path="/events/:name/:shareLink" element={<Userpage />}></Route>
          
          <Route
            path="/events/matchedphotos/:name/:shareLink"
            element={<MatchedPhotosPage />}
          ></Route>

          <Route path="/wedding" element={<Wedding />}></Route>
          <Route path="/social" element={<Social />}></Route>
          <Route path="/school" element={<School />}></Route>
          <Route path="/marathon" element={<Marathons />}></Route>
          <Route path="/convocation" element={<Convocations />}></Route>
          <Route path="/event" element={<Events />}></Route>
          <Route path="/privacy-policy" element={<PrivacyPolicy />}></Route>
          <Route path="/about" Component={AboutUs}></Route>
          <Route path="/faqs" element={<Faq />}></Route>
          <Route path="/contact-us" element={<Contact />}></Route>
          <Route path="/pricing" element={<Pricing />}></Route>
          <Route path="/features" element={<Features/>}></Route>
        
        </Routes>
        
        <Footer />
      
      </Router>
    
    </div>
  );
}

export default App;
