import React from 'react';
import { Box, Image, Heading, Button } from '@chakra-ui/react';
import { apiUrl } from '../../config'; // Import the apiUrl from the config file

const MatchedPhotosPage = ({ matchedPhotos }) => {
 
  const downloadImage = (url, fileName) => {
    const link = document.createElement('a');
    link.href = url;
    link.download = fileName;

    document.body.appendChild(link);
    
    link.click();
    document.body.removeChild(link);
  };

  return (
    <Box>
      <Heading textAlign="center" fontSize="2xl" mb={4}>
        Matched Photos
      </Heading>
      <Box display="flex" flexWrap="wrap" justifyContent="center">
        {matchedPhotos.map((photo, index) => (
          <Box key={index} m={2}>
            <Image src={photo} alt={`Matched Photo ${index + 1}`} boxSize="200px" objectFit="cover" />
            <Button onClick={() => downloadImage(photo, `matched_photo_${index + 1}.png`)} mt={2} colorScheme="teal">
              Download
            </Button>
          </Box>
        ))}
      </Box>
      </Box>


  );
};

export default MatchedPhotosPage;
